import React, { useCallback, useState } from 'react';
import { useSelector } from '../../redux';
import GuestGatewayModal from './GuestGatewayModal';
import { FreeTrialDialog } from './FreeTrialDialog';
import FreeTrialScrollToTop from './FreeTrialScrollToTop';
import { CrossSitePopup } from './CrossSitePopup';
import { SevenDaysOrLessPopup } from './SevenDaysOrLessPopup';
import { generateCampaignUrl } from 'src/client/helpers/urls/campaignUrls';
import { User } from 'src/@types/app';
import { usePopupBanners } from './usePopupBanners';
import { ExpirationPopup } from './ExpirationPopup';
import { AgeVerifyModal } from 'src/client/components/modals/AgeVerifyModal';

export const crossSiteBannerCookie = 'showCrossSiteBanner';
export const showSevenDaysOrLessPromo = 'showSevenDaysOrLess';
export const showExpiringPromo = 'showExpiringPromo';
export const showGuestGatewayCookie = 'showGuestGateway';

const useFreeTrialDialogHook = (user?: User) => {
  const { test } = useSelector((state) => state.app);
  const [isFreeTrialDialogOpen, setFreeTrialDialogOpen] = useState(false);

  const openFreeTrialDialog = useCallback(
    (open) => {
      if (!user && test?.testId) {
        window.VWO = window.VWO || [];
        window.VWO.push(['activate', false, [test.testId], true]);
      }
      setFreeTrialDialogOpen(open);
    },
    [test, user],
  );

  const closeFreeTrialDialog = () => {
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'free-trial',
      eventLabel: 'dialog',
      eventAction: 'hide',
    });
    setFreeTrialDialogOpen(false);
  };

  return {
    isFreeTrialDialogOpen,
    openFreeTrialDialog,
    closeFreeTrialDialog,
  };
};

export const PopupBanners = () => {
  const site = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);
  const {
    config: { featureFlags },
    accessSubdomain,
  } = useSelector((state) => state.app);

  const [activeModal, closeActiveModal] = usePopupBanners();
  const { isFreeTrialDialogOpen, openFreeTrialDialog, closeFreeTrialDialog } = useFreeTrialDialogHook(user);

  const shouldRenderGuestGatewayModal = activeModal === 'showGuestGateway';
  const showCrossSiteBanner = activeModal === 'showCrossSiteModal';
  const shouldShowSevenDaysOrLessAd = activeModal === 'showSevenDaysOrLessModal';
  const shouldShowOnExpirationAd = activeModal === 'showOnExpirationModal';
  const shouldShowAgeVerifyBanner = Boolean(
    featureFlags?.enableEmblemAgeVerification &&
      (user?.hasPPSPurchase || user?.hasAnyValidSubscription) &&
      user?.accountNeedsAgeVerification,
  );

  if (shouldShowAgeVerifyBanner) {
    return <AgeVerifyModal />;
  }

  return (
    <>
      <FreeTrialScrollToTop setFreeTrialDialogOpen={openFreeTrialDialog} />

      {shouldRenderGuestGatewayModal && <GuestGatewayModal onClose={closeActiveModal(showGuestGatewayCookie)} />}

      {featureFlags?.enableExpiringPromoModal && (
        <SevenDaysOrLessPopup
          show={shouldShowSevenDaysOrLessAd}
          onClose={closeActiveModal(showSevenDaysOrLessPromo)}
          campaignUrl={generateCampaignUrl(site, 'one_week', accessSubdomain)}
        />
      )}

      {featureFlags?.enableExpiringPromoModal && (
        <ExpirationPopup
          show={shouldShowOnExpirationAd}
          onClose={closeActiveModal(showExpiringPromo)}
          campaignUrls={{
            expireDay: generateCampaignUrl(site, 'expire_day', accessSubdomain),
            afterExpire: generateCampaignUrl(site, 'after_expire', accessSubdomain),
          }}
        />
      )}

      <CrossSitePopup show={showCrossSiteBanner} onClose={closeActiveModal(crossSiteBannerCookie)} />

      <FreeTrialDialog show={isFreeTrialDialogOpen} onClose={closeFreeTrialDialog} />
    </>
  );
};
