import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro, faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { faGift, faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { faSmilePlus } from '@fortawesome/pro-regular-svg-icons';
import { faFilm, faStars, faWebcam } from '@fortawesome/pro-solid-svg-icons';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { useSelector } from '../../../redux';
import {
  blogRoutes,
  galleriesRoutes,
  modelsAndArtistsRoutes,
  moviesRoutes,
  routes,
  updatesRoutes,
} from '../../../utils/router';
import { HeaderContext } from '../HeaderProvider';
import { MobileLink } from './MobileLink';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { useBlogConfig } from 'src/client/helpers/useFeatureFlags';

type MobileLink = {
  alsoActiveOn?: string[];
  className?: string;
  icon: IconProp;
  label: string;
  requiresAuth?: boolean;
  routes?: Array<string | string[]>;
  to?: string;
  href?: string;
  openNewWindow?: boolean;
};

type Props = {
  isSplash: boolean;
};

export const MobileLinksList = (props: Props) => {
  const config = useSelector((state) => state.app.config);
  const user = useSelector((state) => state.auth.user);
  const intl = useIntl();
  const [joinUrl] = useJoinUrl({ campaign: 'mobile-menu' });
  const { setMobileMenuOpened } = useContext(HeaderContext);
  const generateCamUrlWithParams = useGenerateCamUrlWithParams({
    trackingPosition: 'mobileMenu',
    provider: config?.camProviders?.[0].provider ?? 'streamate',
  });
  const hasBlog = useBlogConfig();

  const mobileMenuLinks = useMemo<MobileLink[]>(() => {
    const links: MobileLink[] = [];

    const noUserOrSubscription = !user || !user.validSubscription;
    if (!config.isWhiteLabel && noUserOrSubscription) {
      links.push({
        label: intl.formatMessage({ id: 'header.links.joinNow', defaultMessage: 'Join Now' }),
        href: joinUrl,
        icon: faSmilePlus,
        routes: [],
        className: 'join-now',
      });
    }

    if (config.hasUpdates) {
      links.push({
        label: intl.formatMessage({
          id: 'header.links.updates',
          defaultMessage: 'Updates',
        }),
        to: '/updates',
        icon: faGift,
        routes: updatesRoutes.map((route) => route.path),
      });
    }

    links.push(
      {
        label: intl.formatMessage({
          id: 'header.links.films',
          defaultMessage: 'Films',
        }),
        to: '/erotic-films',
        icon: faFilm,
        alsoActiveOn: ['Movie'],
        requiresAuth: config.movies.requiresAuth,
        routes: moviesRoutes.map((route) => route.path),
      },
      {
        label: intl.formatMessage({
          id: 'header.links.photos',
          defaultMessage: 'Photos',
        }),
        to: '/galleries',
        icon: faCameraRetro,
        alsoActiveOn: ['Gallery'],
        routes: galleriesRoutes.map((route) => route.path),
      },
      {
        label: intl.formatMessage({
          id: 'header.links.models',
          defaultMessage: 'Models',
        }),
        to: '/models',
        icon: faStars,
        alsoActiveOn: ['Model', 'Country'],
        routes: modelsAndArtistsRoutes.map((route) => route.path),
      },
    );

    if (config.hasAllSites) {
      links.push({
        label: intl.formatMessage({
          id: 'header.links.sites',
          defaultMessage: 'All Sites',
        }),
        to: '/allsites',
        icon: faSitemap,
        routes: [routes.allSites.path],
        requiresAuth: config.allSites.usersOnly,
      });
    }

    if (hasBlog && !config.featureFlags?.enableSeoCategoriesMenu) {
      links.push({
        label: intl.formatMessage({
          id: 'header.links.blog',
          defaultMessage: 'Blog',
        }),
        to: '/blog',
        icon: faNewspaper,
        routes: blogRoutes.map((route) => route.path),
      });
    }

    if (config?.camProviders?.length === 1) {
      links.push({
        label: intl.formatMessage({ id: 'header.links.liveGirls', defaultMessage: 'Live Girls' }),
        href: generateCamUrlWithParams('/cams-list'),
        icon: faWebcam,
        openNewWindow: true,
        routes: [],
      });
    }

    return links.filter((link) => {
      if (link.to === '/erotic-films') {
        return link.requiresAuth ? config.movies.active && !!user : config.movies.active;
      }
      if (link.to === '/blog') {
        return config.blog.active;
      }
      if (link.requiresAuth) {
        return !!user;
      }
      return true;
    });
  }, [generateCamUrlWithParams, config, user, intl, joinUrl, hasBlog]);

  if (props.isSplash) {
    return null;
  }

  return (
    <>
      {mobileMenuLinks.map((item) => (
        <MobileLink key={item.label} {...item} handleClick={() => setMobileMenuOpened(false)} />
      ))}
    </>
  );
};
