import React, { ReactEventHandler, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import EmailSignupSection from 'src/client/containers/Splash/EmailSignupSection/EmailSignupSection';
import { SplashSlider } from 'src/client/containers/Splash/Slider/SplashSlider';
import FooterBar from 'src/client/containers/FooterBar/FooterBar';
import { Intro } from 'src/client/components/splash/Straplez/Intro';
import { Heading } from 'src/client/components/splash/Straplez/Heading';
import { Button } from 'src/client/components/splash/Straplez/Button';
import { Outro, FeaturedGallery, GalleryUpdates } from 'src/client/components/splash/Straplez/index';
import { load } from 'src/client/redux/modules/splash';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import Helmet from 'src/client/containers/Helmet/Helmet';
import VisibilitySensor from 'react-visibility-sensor';
import { PromoFooterBanner } from 'src/client/containers/Promo/PromoFooterBanner';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { PromoBanner } from 'src/client/containers/Promo/PromoBanner';
import { usePlayboyLandingPage } from 'src/client/helpers/usePlayboyLandingPage';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';
import Loading from '../../Loading';
import { OnPageText } from 'src/client/components/OnPageSeo/OnPageText/OnPageText';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

export default function Splash() {
  const dispatch = useDispatch();

  const { freeGalleries, galleryUpdates, movieUpdates } = useSelector((state) => state.splash);
  const user = useSelector((state) => state.auth.user);
  const site = useSelector((state) => state.site);
  const {
    cdnUrl,
    config: { splash },
    siteSubdomain,
  } = useSelector((state) => state.app);

  const guestsOnly = splash?.guestsOnly;
  const components = splash?.components;

  const [isSplashAdVisible, setSplashAdVisible] = useState<boolean>(false);
  const [showSlider, setSliderVisibility] = useState<boolean>(Boolean(components?.sliderComponent?.show));
  const { isPromoBannerActive, isPromoSplashFooterActive } = usePromotions();
  const mainPageUrl = useSettingsSelector('homepage');
  const showPlayboyBanner = usePlayboyLandingPage();
  const showSFWSite = useShowSfwSite();

  const [loadRemaining, setLoadRemaining] = useState(false);
  const loadRemainingContentRef = useRef(null);

  const schemaMarkupOrg = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: `https://${siteSubdomain}.${site.domain}/`,
    logo: `${logoUrl(cdnUrl, site)}`,
    name: `${site.name}`,
  };

  const schemaMarkupWebsite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `https://${siteSubdomain}.${site.domain}/`,
    name: `${site.name}`,
  };

  useEffect(() => {
    const contentRef = loadRemainingContentRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setLoadRemaining(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (contentRef) {
      observer.observe(contentRef);
    }

    return () => {
      if (contentRef) {
        observer.unobserve(contentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!user || !guestsOnly) {
      dispatch(load());
    }
  }, [dispatch, user, guestsOnly]);

  if (user && (guestsOnly || mainPageUrl !== 'splash')) {
    return <Redirect to={redirectHomepages[mainPageUrl]} />;
  }
  // React Helmet will not fire the onload event for link tags.
  // https://github.com/nfl/react-helmet/pull/299
  // Workarounds for doing deferred CSS loading.
  // https://stackoverflow.com/a/59482838
  // https://web.dev/articles/defer-non-critical-css
  const deferredCSSHack = ("this.onload=null; this.rel='stylesheet';" as unknown) as ReactEventHandler<HTMLLinkElement>;

  return (
    <Background backgroundColor={splash?.backgroundColor}>
      <Helmet
        id="homepage"
        variables={{
          siteDomain: site.domain,
        }}
      >
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600;700;800&display=swap"
          as="style"
          onLoad={deferredCSSHack}
        />
        <script type="ld+json">{JSON.stringify(schemaMarkupOrg)}</script>
        <script type="ld+json">{JSON.stringify(schemaMarkupWebsite)}</script>
        <meta property="prerender:ttl" content="86400" />
      </Helmet>
      <SplashWrapper>
        {isPromoBannerActive && !showPlayboyBanner && (
          <VisibilitySensor onChange={setSplashAdVisible} partialVisibility>
            <PromoBanner />
          </VisibilitySensor>
        )}

        {!isPromoBannerActive && (
          <Intro title={components?.introComponent?.title} message={components?.introComponent?.message} />
        )}

        <SeoText contentName="text1" />

        {!showSFWSite && (
          <>
            <SplashSlider show={showSlider} teasers={freeGalleries} hideSlider={() => setSliderVisibility(false)} />

            <EmailSignupSection
              show={!showSlider}
              freeGalleries={freeGalleries}
              isStraplez={true}
              openSlider={() => setSliderVisibility(true)}
            />
          </>
        )}

        {/* The rest of the page is loaded when user scrolls to this element */}
        <div ref={loadRemainingContentRef}></div>

        <Heading title="Latest Films" />

        {loadRemaining && (
          <Suspense fallback={<Loading />}>
            <GalleryUpdates updates={movieUpdates} coverAttributes={{ wide: true, clean: true }} updatesMax={12} />
            <Button title="Show all films" url="/erotic-films" />
            <SeoText longText contentName="text2" />
            <FeaturedGallery button="Watch Now" />
            <Heading title="Latest Photography" />

            <GalleryUpdates updates={galleryUpdates} coverAttributes={{ clean: true }} updatesMax={20} />

            <Button title="Show all galleries" url="/galleries" />
            <Outro
              title={components?.outroComponent?.title}
              message={components?.outroComponent?.message}
              logo={logoUrl(cdnUrl, site)}
              background={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/${site.abbreviation}_footer_bg.jpg`}
            />
          </Suspense>
        )}

        {!isSplashAdVisible && isPromoSplashFooterActive && <PromoFooterBanner />}
      </SplashWrapper>
      <FooterBar />
    </Background>
  );
}

const Background = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#181422'};
`;

const SplashWrapper = styled.div<{
  promotionsActive?: boolean;
}>`
  position: relative;
  z-index: 0;
  ${({ promotionsActive }) => promotionsActive && 'z-index: 1'};

  .splash-hero {
    text-align: center;
  }

  .splash-mobile ins > a > img,
  .splash-tablet ins > a > img,
  .splash-hero ins > a > img {
    height: auto;
    width: 100%;
  }
  .splash-hero ins > a > img {
    max-width: 2162px;
  }
`;

const SeoText = styled(OnPageText)<{ longText?: boolean }>`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem;
  justify-content: center;
  background-color: ${(props) => (props.longText ? props.theme.primary1 : 'transparent')};
  text-align: ${(props) => (props.longText ? 'justify' : 'center')};

  h2 {
    padding-bottom: 0.5rem;
  }

  @media ${TABLET_PORTRAIT} {
    text-align: center;
    padding: 0.5rem 0;
  }
`;
