import { SfwFlags } from '@tovia/man-protos/dist/types/content.types';
import { useSelector } from 'src/client/redux';
import { useCallback } from 'react';
import { useShowSfwSite } from '../useFeatureFlags';

export type CoverAttributes = {
  clean?: boolean;
  thumbnail?: boolean;
  wide?: boolean;
  scrapeUUID?: boolean;
};

type Item = {
  coverCleanImagePath?: string;
  coverCleanImagePathSfw?: string;
  coverImagePath?: string;
  coverImagePathSfw?: string;
  sfwFlags?: SfwFlags;
  siteUUID: string;
  thumbnailCoverPath: string;
  thumbnailCoverPathSfw?: string;
  splashImagePath?: string;
  splashImagePathSfw?: string;
  UUID: string;
};

type GetGalleryCoverUrlFn = (
  contentCdnUrl: string,
  item: Item,
  coverAttributes?: CoverAttributes,
  sfw?: boolean,
) => {
  blurImage: boolean;
  url: string;
};

// thumbnailCoverPath is the only one that's been enforced to always be here via
// typescript, and UUID is unreliable for certain lookups:
// (photographers link to a set, not their own uuid)
// Example:
//    /media/C0725CB16C8709041D71CDA87CCF94EF/t_cover_C0725CB16C8709041D71CDA87CCF94EF.jpg
const extractUUIDFromPath = (thumbnailCoverPath: string, fallbackUUID: string) => {
  const regex = /\/media\/([0-9A-F]{32})\/t_cover_([0-9A-F]{32})\.jpg/i;
  const matches = thumbnailCoverPath.match(regex);

  if (matches && matches[1] && matches[1] === matches[2]) {
    return matches[1];
  }

  return fallbackUUID;
};

const getGalleryCoverUrl: GetGalleryCoverUrlFn = (contentCdnUrl, item, coverAttributes, sfw) => {
  const { clean = false, thumbnail = false, wide = false, scrapeUUID = false } = coverAttributes || {};

  const itemUUID = scrapeUUID ? extractUUIDFromPath(item.thumbnailCoverPath, item.UUID) : item.UUID;

  const baseImage = () => {
    switch (true) {
      case wide && clean:
        return 'wide_clean';
      case wide:
        return 'wide';
      case clean:
        return 'clean';
      default:
        return 'cover';
    }
  };

  const getSfwPrefix = () => {
    switch (true) {
      case wide && clean && item.sfwFlags?.hasSfwWideCleanCover:
      case wide && !clean && item.sfwFlags?.hasSfwWideCover:
      case !wide && clean && item.sfwFlags?.hasSfwCleanCover:
      case !wide && !clean && item.sfwFlags?.hasSfwPortraitCover:
        return 'sfw';
      default:
        return '';
    }
  };

  const thumbnailPrefix = thumbnail ? 't' : '';
  const sfwPrefix = sfw ? getSfwPrefix() : '';

  const blurImage = Boolean(sfw && !sfwPrefix);
  const coverPath = [thumbnailPrefix, sfwPrefix, baseImage(), itemUUID].filter(Boolean).join('_');
  const url = [contentCdnUrl, item.siteUUID, 'media', itemUUID, `${coverPath}.jpg`].join('/');

  return { url, blurImage };
};

export const useGetGalleryCoverUrl = () => {
  const sfwEnabled = useShowSfwSite();
  const { contentCdnUrl } = useSelector((state) => state.app);

  return useCallback(
    (cdnUrl: string, item: Item, coverAttributes?: CoverAttributes) => {
      const actualCdnUrl = cdnUrl || contentCdnUrl;
      return getGalleryCoverUrl(actualCdnUrl, item, coverAttributes, sfwEnabled);
    },
    [sfwEnabled, contentCdnUrl],
  );
};
