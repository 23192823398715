import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import {
  routes,
  blogRoutes,
  galleriesRoutes,
  modelsAndArtistsRoutes,
  moviesRoutes,
  updatesRoutes,
} from '../../utils/router';
import { MenuNavLink } from 'src/client/containers/HeaderBar/styles';
import { useBlogConfig } from 'src/client/helpers/useFeatureFlags';

type MenuLink = {
  text: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: any;
  requiresAuth?: boolean;
  values?: Record<string, string>;
};

type Props = {
  isSplash: boolean;
};

export const InternalLinks = (props: Props) => {
  const user = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.app.config);
  const location = useLocation();
  const hasBlog = useBlogConfig();

  const menuLinks = useMemo<MenuLink[]>(() => {
    const links: MenuLink[] = [];

    if (config.hasUpdates) {
      links.push({
        text: 'UPDATES',
        url: '/updates',
        routes: updatesRoutes.map((route) => route.path),
      });
    }

    links.push(
      {
        text: 'FILMS',
        url: '/erotic-films',
        routes: moviesRoutes.map((route) => route.path),
        requiresAuth: config.movies.requiresAuth,
      },
      {
        text: 'PHOTOS',
        url: '/galleries',
        routes: galleriesRoutes.map((route) => route.path),
      },
      {
        text: 'MODELS',
        url: '/models',
        routes: modelsAndArtistsRoutes.map((route) => route.path),
      },
    );

    if (config.hasAllSites) {
      links.push({
        text: 'ALLSITES',
        url: '/allsites',
        routes: [routes.allSites.path],
        requiresAuth: config.allSites.usersOnly,
      });
    }

    if (hasBlog && !config.featureFlags?.enableSeoCategoriesMenu) {
      links.push({
        text: 'BLOG',
        url: '/blog',
        routes: blogRoutes.map((route) => route.path),
      });
    }

    return links.filter((link) => {
      if (link.url === '/erotic-films') {
        return link.requiresAuth ? config.movies.active && !!user : config.movies.active;
      }
      if (link.url === '/blog') {
        return config.blog.active;
      }
      if (link.requiresAuth) {
        return !!user;
      }
      return true;
    });
  }, [config, user, hasBlog]);

  // const whiteLabelSplashUser = config.isWhiteLabel && isSplash && user;
  // if (!isSplash || (config.isWhiteLabel && isSplash && user)) {
  if (props.isSplash && !(config.isWhiteLabel && props.isSplash && user)) {
    return null;
  }

  const isLinkActive = (link: MenuLink) =>
    !!matchPath(location.pathname, {
      path: link.routes,
      exact: true,
    });

  return (
    <div className="hidden-xs hidden-sm pull-right">
      <nav>
        {menuLinks.map((link) => (
          <MenuNavLink to={link.url} className={cx({ active: isLinkActive(link) })} key={link.url}>
            <FormattedMessage
              id={`header.links.${link.text.toLowerCase()}`}
              defaultMessage={link.text}
              values={link.values}
            />
          </MenuNavLink>
        ))}
      </nav>
    </div>
  );
};
